<template>
    <div class="card" :style="{ backgroundColor: cardColor }">
      <div class="card-image-container">
        <i class="fas fa-cross card-icon white-icon"></i>
      </div>
      <div class="card-content">
        <p style="font-size:28px;height:50px">{{ nome.toUpperCase() }}</p>
        <p style="font-size:22px;"><b>Falecimento: </b>{{ falecimento }}</p>
        <p style="font-size:22px;"><b>Sepultamento: </b> {{ sepultamento }}</p>
        <p style="font-size:22px;"><b>Tipo: </b> {{ quadra }}  <b> Quadra: </b>{{ letra }}</p>
        <p style="font-size:22px;"><b>Nº: </b>{{ jazigo }}</p>
        <button class="print-button" @click="sendDataToAndroid">
          <i class="fas fa-print"></i> Imprimir
        </button>

      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      nome: String,
      falecimento: String,
      sepultamento: String,
      quadra: String,
      letra: String,
      jazigo: String,
      cardColor: String,
    },




    methods: {

      printCard() {
      // Aqui você pode realizar a ação de impressão ou qualquer outra ação desejada
      //console.log('Informações do card:', this.nome, this.falecimento, this.sepultamento, this.quadra, this.letra, this.jazigo, this.cardColor);
      // Aqui você pode realizar a ação de impressão ou qualquer outra ação desejada
      // Por exemplo, iniciar uma nova atividade ou janela de impressão
      // Ou executar ação de impressão
      
      // Por exemplo, iniciar uma nova atividade ou janela de impressão
      // Ou executar ação de impressão
    },

    sendDataToAndroid() {
      const data = {
        nome: this.nome,
        falecimento: this.falecimento,
        sepultamento: this.sepultamento,
        quadra: this.quadra,
        letra: this.letra,
        jazigo: this.jazigo,
        cardColor: this.cardColor,
      };

      // Enviar os dados para a Activity nativa do Android
      if (window.AndroidInterface && typeof window.AndroidInterface.sendData === 'function') {
        window.AndroidInterface.sendData(JSON.stringify(data));
      }

    },

    formatDate(dateString) {

      if (!dateString) {
      return ''; // Retorna uma string vazia se dateString for nulo
    }

      const date = new Date(dateString);
      const formattedDate = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
      return formattedDate;
    },
    formatTime(dateTimeString) {
      const time = new Date(dateTimeString);
      const options = { hour: '2-digit', minute: '2-digit' };
      return time.toLocaleTimeString('pt-BR', options);
    },
  },
  };
  </script>
  
  <style>
  /* Estilize o card conforme necessário */
  .card {
    display: inline-block;
    width: 620px;
    height: 420px;
    margin: 10px;
    border-radius: 5px;
    text-align: center;
  }
  
  .card-icon {
    margin-top:10px;
    font-size: 26px; /* Tamanho da fonte para o ícone */
  }
  
  .white-icon {
    color: white; /* Cor branca para o ícone */
  }
  
  .print-button {
    background-color: #3a7091; /* Cor de fundo do botão */
    color: white; /* Cor do texto do botão */
    padding: 10px 20px; /* Espaçamento interno do botão */
    border: none; /* Remover a borda do botão */
    border-radius: 5px; /* Arredondar as bordas do botão */
    cursor: pointer; /* Cursor de mão ao passar o mouse sobre o botão */
    width: 50%; /* Largura do botão */
    font-size: 20px; /* Tamanho do texto do botão */
    transition: background-color 0.2s, color 0.2s; /* Efeito de transição suave */
  }

  
  .card-content {
    color: white;
    padding: 5px;
  }

  .card-image {
    width: 100px;
    height: 100px;
    margin: 10px auto;
  }
  
  .card-content {
    color: white;
    padding: 5px;
  }
  </style>
  